import gql from 'graphql-tag'

export const User = {
  fragments: {
    UserBasicData: gql`
      fragment UserBasicData on User {
        _id
        name
        email
      }`,
    UsersListingData: gql`
      fragment UsersListingData on User {
        _id
        name
        email
        country
        first_name
        last_name
        avatar
        type
        gender
        social
        createdAt
        serviceInterests
        currentPosition
        phone
        invitationStatus
        createdByAdmin
        age
        loginDate
        eso {
          _id
          name
          countryName
        }
      }`,
    UserDataAdmin: gql`
    fragment UserDataAdmin on User{
      first_name
      last_name
      email
      gender
      age
      birthDate
      currentPosition
      avatar
      phone
      city
      country
      social
      serviceInterests
      personalInterests
      about
      type
      address
    }`
  }
}

export const Admin = {
  fragments: {
    AdminData: gql`
      fragment AdminData on User {
        _id
        name
        email
      }
    ` },
}
export const Esos = {
  fragments: {
    EsosListingData: gql`
      fragment EsosListingData on ESO {
      _id
      name
      published
      featured
      partner
      invitition_date
      registration_date
      invited
      registered
      slug
      createdAt
      }`,
    EsoData: gql`
      fragment EsoData on ESO {
    _id
      name
      slug
      user {
        email
        _id
      }
      featured
      invitition_date
      registration_date
      partner
      published
      invited
      registered
      countryName
      countryCode
      logo
      categories
      services
      location
      locationName
      place_id
      description
      address
      website
      contact
      phone
      email
      city
      mainOffice
      branchOffices
      language
      established
      categories
      categoriesOther
      legalEntity
      projectDuration
      programName
      geoActive
      geoActiveOther
      otherCountriesActive
      targetGroups
      targetGroupsOther
      growthStagesSupported
      targetSectors
      targetSectorsOther
      services
      servicesOther
      entrepreneurDurationSupport
      outreachStrategy
      outreachStrategyOther
      outreachSocialMediaStrategy
      outreachSocialMediaStrategyOther
      founder
      founderOther
      operationalCosts
      fundingSources
      fundingSourcesOther
      fundingSourcesNumber
      entrepreneurParticipationFee
      entrepreneurParticipationFeeAmount
      financialSuportProvided
      financialSuportProvidedOther
      programChallenges
      programChallengesOther
      supportNeeded
      supportNeededOther
      staffFullTime
      staffPartTime
      staffMentorsCoaches
      staffVolunteersInterns
      womenStaff
      directedByWoman
      entrepreneursSupported
      newBusinessesCreated
      existingBusinessesSupported
      jobsCreated
      surveySent
      surveyFilled
      dateChecked
      source
      social
  }
`,
  },
}

export const News = {
  fragments: {
    NewsListingData: gql`
      fragment NewsListingData on NewsItem {
        _id
        title
        slug
        online
        published
        isApproved
        featured
        type
        languages
        createdAt
      }
    `,
    NewsData: gql`
      fragment NewsData on NewsItem {
        _id
        title
        slug
        online
        published
        isApproved
        featured
        content
        images
        categories
        url
        type
        languages
        dateFrom
        dateTo
        country
        additionalCountries
        city
      }
    `

  },

}

export const Survey = {
  fragments: {
    SurveyListingData: gql`
    fragment SurveyListingData on SurveyItem{
      _id
      slug
      title
      published
      type
      languages
      createdAt
      dateFrom
      dateTo
    }
    `,
    SurveyData: gql`
    fragment SurveyData on SurveyItem{
      _id
      title
      slug
      published
      categories
      type
      languages
      url
      openAs
      createdAt
      content
      dateFrom
      dateTo
      images
      message
      status
    }`
  }
}

export const Resource = {
  fragments: {
    ResourceListData: gql`
    fragment ResourceListData on Resource {
      _id
      createdAt
      category
      status
      language
      name
      author {
        _id
        name
      }
      isHighlight
    }
    `,
    ResourceData: gql`
    fragment ResourceData on Resource {
      _id
      createdAt
      status
      fileUrl {
        url
        extension
      }
      language
      name
      images
      description
      name
      content
      category
      functions
      sectors
      price
      countryCode
      countryName
      additionalCountries
      location
      address
      city
      deadline
      workingLanguages
      contact
      phone
      email
      website
      author {
        _id
        name
        avatar
      }
      targets
      services
      stages      
      subCategories
      amount
      fromDate
      toDate
    }
    `,
  },
  locationDetails: gql`
    fragment locationDetails on GeoLocation{
      LocationResult
    }
    `
}

export const CustomIndicator = {
  fragments: {
    CustomIndicatorData: gql`
      fragment CustomIndicatorData on CustomIndicator {
        _id
        pillar
        outcome
        name
        value
        startDate
        endDate
      }
    `
  }
}

export const Carousel = {
  fragments: {
    CarouselListingData: gql`
    fragment CarouselListingData on CarouselItem{
      _id
      title
      isActive
      languages
      createdAt
      createdBy
    }
    `,
    CarouselData: gql`
    fragment CarouselData on CarouselItem{
      _id
      url
      title
      isActive
      languages
      createdAt
      content
      image
      btnTitle
      createdBy
    }`
  }
}

export const FAQ = {
  fragments: {
    FAQListingData: gql`
    fragment FAQListingData on FAQItem{
      _id
      question
      description
      languages
      createdAt
    }
    `,
    FAQData: gql`
    fragment FAQData on FAQItem{
      _id
      question
      description
      languages
      docs
      iframeURL
      url
      createdAt
    }`
  }
}

export const DynamicLanguage = {
  fragments: {
    DynamicLanguageData: gql`
      fragment DynamicLanguageData on LanguageItem{
        _id
        langIndex
        langName
        langCode
        isActive
        message
        status
        translationFile
        createdBy
        createdAt
        updatedAt
      }
    `
  }
}
export const DynamicSurvey = {
  fragments: {
    DynamicSurveyListingData: gql`
    fragment DynamicSurveyListingData on DynamicSurveyItem{
      _id
      title
      isPublic
      isActive
      isAnsweredBy
      isNotificationSent
      createdAt
    }
    `,
    DynamicSurveyData: gql`
    fragment DynamicSurveyData on DynamicSurveyItem{
      _id
      title
      description
      goal
      image
      thumbnail_image
      isFilledBy
      isActive
      questionType
      isPublic
      time_required_for_survey
      survey_start_from
      questions
      questionGroup
      targetDemographics
      questions
      createdAt
    }`,
    DynamicSurveyAnalyticsData: gql`
    fragment DynamicSurveyAnalyticsData on DynamicSurveyAnalyticsItem{
      analyticsData
    }`
  }
}

export const ResourceAnalytics = {
  fragments: {
    ResourceAnalyticsListingData: gql`
    fragment ResourceAnalyticsListingData on AnalyticsItem{
        _id 
        resourceName 
        downloadCount 
        analyticType
        user{_id name}
        updatedAt 
        createdAt
    }
    `,
    GA4AnalyticsListingData: gql`
    fragment GA4AnalyticsListingData on GA4AnalyticsItem{
        _id 
        analyticType
        pagePath
        pageViewCount
        scrollPage
        scrollCount
        createdAt
        updatedAt
    }
    `,
    RecurringUserListingData: gql`
    fragment RecurringUserListingData on RecurringAnalyticsItem{
      _id
      user_id
      user_name
      totalDownloads
    }
    `,
    RedirectLinkAnalyticsListingData: gql`
    fragment RedirectLinkAnalyticsListingData on RedirectLinkAnalyticsItem{
      _id
      link
      moduleName
      moduleTitle
      analyticType
      redirectCount
      user{_id name}
    }
    `,
  }
}